import { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'store';
import { getReminder } from 'store/slices/calendar';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

// assets
import useConfig from 'hooks/useConfig';

const intervalMins = 5;

// ==============================|| COUNTDOWN ||============================== //
const Reminder = () => {
  const { borderRadius } = useConfig();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { reminders } = useSelector((state) => state.calendar);

  const notifyMe = () => {
    if (!window.Notification) {
      console.log('Browser does not support notifications.')
    } else {
      // check if permission is already granted
      if (Notification.permission === 'granted') {
        // show notification here
        new Notification('Lanreé Admin', {
          body: '⏰ Social Media Reminder',
          // icon: 'https://bit.ly/2DYqRrh'
        })
      } else {
        // request permission from the user
        Notification.requestPermission()
          .then(function (p) {
            if (p === 'granted') {
              // show notification here
              new Notification('Lanreé Admin', {
                body: '⏰ Social Media Reminder',
                // icon: 'https://bit.ly/2DYqRrh'
              })
            } else {
              console.log('User has blocked notifications.')
            }
          })
          .catch(function (err) {
            console.error(err)
          })
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(function () {
      dispatch(getReminder());
    }, 1000 * 60 * intervalMins); // Every ${intervalMins} Mins (1000 * 60 * intervalMins)

    return () => { // this should work flawlessly besides some milliseconds lost here and there 
      clearInterval(interval);
    }
  }, [dispatch]);

  useEffect(() => {
    if (reminders.length > 0) {
      setIsModalOpen(true);
      notifyMe();
    } else {
      setIsModalOpen(false);
    }
  }, [reminders]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleReminderSuccess = () => {
    let disabledReminderIds = JSON.parse(
      sessionStorage.getItem("disabled_reminder_ids") || "[]"
    );
    if (reminders.length > 0) {
      reminders.forEach((r: any) => {
        if (!disabledReminderIds.includes(r.id)) disabledReminderIds.push(r.id);
      });
      sessionStorage.setItem("disabled_reminder_ids", JSON.stringify(disabledReminderIds));
    }
    handleModalClose();
  }

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleModalClose} open={isModalOpen}>
      <DialogTitle>
        📸 Social Media Reminder ⏰
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: 14 }}>Please take photos & videos of the current treatments below:</Typography>
        {reminders.map(r => (
          <Box key={r.id} p={2} mt={2} sx={{ backgroundColor: theme.palette.grey[50], borderRadius: `${borderRadius}px`, }}>
            <Box>
              <Typography variant="caption">Customer</Typography>
              <Typography variant="h5">{r.customer.name}</Typography>
            </Box>
            <Box mt={1}>
              <Typography variant="caption">Treatment</Typography>
              <Typography variant="h5">{r.cartProduct.product.name}</Typography>
            </Box>
          </Box>
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button type="button" variant="outlined" onClick={handleModalClose}>
                Remind me in {intervalMins} mins
              </Button>
              <Button type="submit" variant="contained" onClick={handleReminderSuccess}>
                Got it!
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
};

export default Reminder;
