// project imports
import services from 'utils/mockAdapter';

// types
import { Service } from 'types/service';

const serviceList: Service[] = [
    { id: 1, name: 'Laser Hair Removal', category: 'Service', duration: '30', status: 'Active', emailSpecialNotes: '', },
    { id: 2, name: 'CryoSculpt Slimming', category: 'Service', duration: '30', status: 'Active', emailSpecialNotes: '', },
    { id: 3, name: 'Consultation', category: 'Service', duration: '30', status: 'Active', emailSpecialNotes: '', },
];


// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/service/list').reply(200, { serviceList });

