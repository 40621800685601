// third-party
import { createSlice } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { API_HOST } from 'config';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['service'] = {
    error: null,
    services: [],
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET STAFFS
        getServiceSuccess(state, action) {
          state.services = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getServices() {
  return async () => {
    try {
      const response = await axios.get(`${API_HOST}/product`);
      // console.log(response.data);
      dispatch(slice.actions.getServiceSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createService(service: FormikValues) {
  return async () => {
    try {
        await axios.post(`${API_HOST}/product`, service);
        // dispatch(openSnackbar(snackbar('New service added')));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateService(id: number, data: FormikValues) {
  return async () => {
    try {
      await axios.patch(`${API_HOST}/product/${id}`, data);
      // dispatch(openSnackbar(snackbar(`Service (id: ${id}) updated`)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteService(id: number) {
  return async () => {
    try {
      await axios.delete(`${API_HOST}/product/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
