// third-party
// import { v4 as UIDV4 } from 'uuid';
import { set, sub } from "date-fns";
import _ from "lodash";

// project imports
import services from "utils/mockAdapter";

// types
import { CalendarEventProps } from "types/calendar";

// color variants
import value from "assets/scss/_themes-vars.module.scss";

// calendar events
let events: CalendarEventProps[] = [
  {
    id: 1,
    serviceId: 1,
    serviceName: "Derma Shine Skin Booster",
    customerId: 1031,
    customerName: "Nao nao",
    customerSalutation: "Ms",
    customerEmail: "naonao@test.com",
    customerPhone: "021123123",
    // customerRemark: '',
    staffId: 1,
    sendNotification: false,
    requestNotes: "Did not pay deposit, pay 3 sessions package",
    status: "Approved",
    finalTextReminder: false,
    firstEmailReminder: false,
    secondEmailReminder: false,
    start: set(new Date(), { hours: 8, minutes: 30 }),
    end: set(new Date(), { hours: 12, minutes: 30 }),
  },
  {
    id: 2,
    serviceId: 1,
    serviceName: "Derma Shine Skin Booster",
    customerId: 1031,
    customerName: "Nao nao",
    customerSalutation: "Ms",
    customerEmail: "naonao@test.com",
    customerPhone: "021123123",
    // customerRemark: '',
    staffId: 1,
    sendNotification: false,
    requestNotes: "Did not pay deposit, pay 3 sessions package",
    status: "Approved",
    finalTextReminder: false,
    firstEmailReminder: false,
    secondEmailReminder: false,
    start: sub(set(new Date(), { hours: 9, minutes: 25 }), { days: 1 }),
    end: sub(set(new Date(), { hours: 13, minutes: 45 }), { days: 1 }),
  },
  {
    id: 3,
    serviceId: 2,
    serviceName: "Fotona Laser Treatment",
    customerId: 6665,
    customerName: "Nao nao",
    customerSalutation: "Ms",
    customerEmail: "test@test1.com",
    customerPhone: "13431413",
    // customerRemark: '',
    staffId: 1,
    sendNotification: false,
    requestNotes: "Did not pay deposit, pay 3 sessions package",
    status: "Approved",
    finalTextReminder: false,
    firstEmailReminder: false,
    secondEmailReminder: false,
    start: set(new Date(), { hours: 12, minutes: 30 }),
    end: set(new Date(), { hours: 14, minutes: 30 }),
  },
  {
    id: 4,
    serviceId: 2,
    serviceName: "Fotona Laser Treatment",
    customerId: 6665,
    customerName: "Kim Pannell",
    customerSalutation: "Ms",
    customerEmail: "kim@test.com",
    customerPhone: "03214314",
    customerRemark: "Allow to post on social media",
    staffId: 2,
    sendNotification: false,
    requestNotes: "Natalee Taylor ultraformer face + double chin",
    status: "Cancelled",
    finalTextReminder: false,
    firstEmailReminder: false,
    secondEmailReminder: false,
    start: set(new Date(), { hours: 10, minutes: 30 }),
    end: set(new Date(), { hours: 13, minutes: 30 }),
  },
  {
    id: 5,
    serviceId: 3,
    serviceName: "Lumenis PiQo4 Laser Facial",
    customerId: 7383,
    customerName: "(Yan) Rebecca Lin",
    customerSalutation: "Ms",
    customerEmail: "rebeccalin@gmail.com",
    customerPhone: "032442523",
    customerRemark: "Allow to post on social media",
    staffId: 3,
    sendNotification: false,
    requestNotes: "2nd session, sign form",
    status: "Approved",
    finalTextReminder: false,
    firstEmailReminder: false,
    secondEmailReminder: false,
    start: set(new Date(), { hours: 16, minutes: 30 }),
    end: set(new Date(), { hours: 17, minutes: 30 }),
  },
];

// ==============================|| MOCK SERVICES ||============================== //
services.onGet("/api/calendar/events").reply((request) => {
  try {
    events = events.map((e) => ({
      ...e,
      color: e.status === "Cancelled" ? value.errorMain : value.secondaryMain,
      title: e.customerRemark
        ? `${e.customerSalutation} ${e.customerName}
                Remark: ${e.customerRemark}
                Service: ${e.serviceName}`
        : `${e.customerSalutation} ${e.customerName}
                Service: ${e.serviceName}`,
    }));
    // console.log(events);
    return [200, events];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

services.onPost("/api/calendar/events/new").reply((request) => {
  try {
    const {
      id,
      serviceId,
      serviceName,
      customerId,
      customerName,
      customerSalutation,
      customerEmail,
      customerPhone,
      customerRemark,
      staffId,
      sendNotification,
      requestNotes,
      status,
      finalTextReminder,
      firstEmailReminder,
      secondEmailReminder,
      start,
      end,
    } = JSON.parse(request.data);
    const event = {
      id,
      serviceId,
      serviceName,
      customerId,
      customerName,
      customerSalutation,
      customerEmail,
      customerPhone,
      customerRemark,
      staffId,
      sendNotification,
      requestNotes,
      status,
      finalTextReminder,
      firstEmailReminder,
      secondEmailReminder,
      start,
      end,
    };

    events = [...events, event];

    return [200, events];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

services.onPost("/api/calendar/events/update").reply((request) => {
  try {
    const { eventId, update } = JSON.parse(request.data);

    events = _.map(events, (_event) => {
      if (_event.id === eventId) {
        _.assign(_event, { ...update });
      }

      return _event;
    });

    return [200, { events }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

services.onPost("/api/calendar/events/remove").reply((request) => {
  try {
    const { eventId } = JSON.parse(request.data);
    events = _.reject(events, { id: eventId });

    return [200, events];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
