import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useDispatch } from 'store';
import { updateCustomerSearchStr } from 'store/slices/customer';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, Grid, InputAdornment, OutlinedInput, Popper } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconArrowBarUp, IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '100%',
  top: '-45px !important',
  // paddingLeft: '10px',
  // paddingRight: '10px',
  // [theme.breakpoints.down('sm')]: {
  //   padding: '0 10px'
  // }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 0,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
  }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
  }
}));

interface Props {
  value: string;
  setValue: (value: string) => void;
  searchCustomer: () => void;
  popupState: any;
}

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, searchCustomer, popupState }: Props) => {
  const theme = useTheme();
  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          searchCustomer();
          popupState.toggle();
        }
      }}
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <HeaderAvatarStyle variant="rounded" onClick={() => {
            searchCustomer();
            popupState.toggle();
          }}>
            <IconSearch stroke={1.5} size="1.3rem" />
          </HeaderAvatarStyle>
          <HeaderAvatarStyle sx={{ ml: 1.5 }} variant="rounded" onClick={() => setValue('')}>
            <IconX stroke={1.5} size="1.3rem" />
          </HeaderAvatarStyle>
          <Box sx={{ ml: 1.5 }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
                color: theme.palette.orange.dark,
                '&:hover': {
                  background: theme.palette.orange.dark,
                  color: theme.palette.orange.light
                }
              }}
              {...bindToggle(popupState)}
            >
              <IconArrowBarUp stroke={1.5} size="1.3rem" />
            </Avatar>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ 'aria-label': 'weight' }}
    />
  );
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const handleCustomerSearch = () => {
    dispatch(updateCustomerSearchStr(value));
    navigate('/customers');
  };

  const handleCustomerSearchClear = () => {
    setValue('');
    dispatch(updateCustomerSearchStr(null));
    navigate('/customers');
  }

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box>
                <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                  <IconSearch stroke={1.5} size="1.2rem" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions type="slide" direction="down" {...TransitionProps}>
                      <Card
                        sx={{
                          background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                          [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: '0 0 15px rgba(0,0,0,.25)',
                          }
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                              <MobileSearch
                                value={value}
                                setValue={setValue}
                                searchCustomer={handleCustomerSearch}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <OutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleCustomerSearch();
            }
          }}
          placeholder="Search customer"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <HeaderAvatarStyle variant="rounded" onClick={handleCustomerSearch} sx={{ mr: 1 }}>
                <IconSearch stroke={1.5} size="1.3rem" />
              </HeaderAvatarStyle>
              <HeaderAvatarStyle variant="rounded" onClick={handleCustomerSearchClear}>
                <IconX stroke={1.5} size="1.3rem" />
              </HeaderAvatarStyle>
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ 'aria-label': 'weight' }}
          sx={{ width: '450px !important' }}
        />
      </Box>
    </>
  );
};

export default SearchSection;
