// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['error'] = {
    error: null,
    message: null,
    statusCode: null,
};

const slice = createSlice({
    name: 'error',
    initialState,
    reducers: {
      // HAS ERROR
      hasError(state, action) {
        state.error = action.payload.error;
        state.message = action.payload.message;
        state.statusCode = action.payload.statusCode;
      },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Add a request interceptor
axios.interceptors.request.use(async (config: any) => {
  let access_token = sessionStorage.getItem('access_token');
  if (!!localStorage.getItem('remember_me')) {
    access_token = await firebase?.auth()?.currentUser?.getIdToken(true) || null;
  }
  config.headers.Authorization =  `Bearer ${access_token}`;
  return config;
});
