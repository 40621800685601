import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
// import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
  ...theme.typography.mainContent,
  [theme.breakpoints.down("sm")]: {
    margin: 0,
    padding: '70px 10px 10px',
  },
  [theme.breakpoints.up("sm")]: {
    margin: 0,
    padding: '70px 0 0',
  },
  [theme.breakpoints.up("md")]: {
    padding: '80px 0 0',
  },
  // [theme.breakpoints.up("lg")]: {
  //   padding: '85px 0 0',
  // },
  '&.bookings ': {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: '150px 10px 10px',
    },
    [theme.breakpoints.up("sm")]: {
      margin: 0,
      padding: '155px 0 0',
    },
    [theme.breakpoints.up("md")]: {
      padding: '80px 0 0',
    },
    [theme.breakpoints.up("lg")]: {
      height: '100vh',
      overflow: 'hidden',
    },
  },
  ...(!open && {
    // borderBottomLeftRadius: 0,
    // borderBottomRightRadius: 0,
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.shorter
    // }),
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: -(drawerWidth - 20),
    //   width: `calc(100% - ${drawerWidth}px)`
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: '20px',
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   padding: '16px'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // }
  }),
  ...(open && {
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.shorter
    // }),
    // marginLeft: 0,
    // borderBottomLeftRadius: 0,
    // borderBottomRightRadius: 0,
    // width: `calc(100% - ${drawerWidth}px)`,
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: '20px'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: '10px'
    // }
  })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { container } = useConfig();

  const { pathname } = useLocation();

  React.useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const header = useMemo(
    () => (
      <Toolbar sx={{ pt: { xs: 1, sm: 2, lg: 2 }, pb: { xs: 1, sm: 2, lg: 2 } }}>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          // transition: drawerOpen ? theme.transitions.create('width') : 'none'
        }}
      >
        {header}
      </AppBar>

      {/* drawer */}
      <Sidebar />

      {/* main content */}
      <Main theme={theme} open={drawerOpen} className={pathname.replace('/', '')}>
        {/* breadcrumb */}
        {container && (
          <Container maxWidth="lg">
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
            <Outlet />
          </Container>
        )}
        {!container && (
          <>
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
            <Outlet />
          </>
        )}
      </Main>
    </Box>
  );
};

export default MainLayout;
