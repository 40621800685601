// project imports
import services from 'utils/mockAdapter';

// types
import { Staff } from 'types/staff';

const staffList: Staff[] = [
    { id: 1, name: 'Luvina', role: 'Therapist', status: 'Active', startTime: '8:00 am', endTime: '10:00 pm' },
    { id: 2, name: 'Jenny', role: 'Therapist', status: 'Active', startTime: '8:00 am', endTime: '10:00 pm' },
    { id: 3, name: 'Kye', role: 'Therapist', status: 'Active', startTime: '8:00 am', endTime: '10:00 pm' },
];


// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/staff/list').reply(200, { staffList });

