// third-party
import _ from "lodash";

// project imports
import services from "utils/mockAdapter";

// types
import { Customer, CustomerDetails } from "types/customer";

const customerList: Customer[] = [
  {
    id: 1,
    name: "Joseph William 1",
    phone: "021 234 6475",
    email: "Joseph@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 2,
    name: "Ashy Handgun 2",
    phone: "021 234 6475",
    email: "Ashy@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 3,
    name: "Larry Doe 3",
    phone: "021 234 6475",
    email: "Larry@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 4,
    name: "Sara Soudan 4",
    phone: "021 234 6475",
    email: "Sara@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 5,
    name: "Joseph William 5",
    phone: "021 234 6475",
    email: "Joseph@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 6,
    name: "Aisha Handgun 6",
    phone: "021 234 6475",
    email: "Aisha@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 7,
    name: "Larky Doe 7",
    phone: "021 234 6475",
    email: "Larky@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 8,
    name: "Sara Soupier 8",
    phone: "021 234 6475",
    email: "Sara@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 9,
    name: "Joseph William 9",
    phone: "021 234 6475",
    email: "Joseph@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 10,
    name: "Anshan Handgun 10",
    phone: "021 234 6475",
    email: "Anshan@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 11,
    name: "Lardy Doe 11",
    phone: "021 234 6475",
    email: "Lardy@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 12,
    name: "Sara Soudan 12",
    phone: "021 234 6475",
    email: "Sara@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 13,
    name: "Joseph William 13",
    phone: "021 234 6475",
    email: "Joseph@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 14,
    name: "Ashy Handgun 14",
    phone: "021 234 6475",
    email: "Ashy@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 15,
    name: "Lars Doe 15",
    phone: "021 234 6475",
    email: "Lars@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
  {
    id: 16,
    name: "Sara Souvenir 16",
    phone: "021 234 6475",
    email: "Sara@mail.com",
    credit: 1432,
    tier: { id: 1, name: "test" },
    remark: "",
  },
];

const customerDetails: CustomerDetails[] = [
  {
    id: 1,
    name: "Joseph William 1",
    phone: "021 234 6475",
    email: "Joseph@mail.com",
    tier: { id: 1, name: "test" },
    salutation: "Mr",
    remark: "Allow to post on instagram",
    dob: null,
    anniversary: null,
    address: {
      id: 1,
      addressLine1: "128 Parnell Rd",
      addressLine2: "",
      city: "Auckalnd",
      country: "New Zealand",
      postcode: "1052",
      suburb: "Parnell",
    },
    medicalHistory: {
      additionalDetails: "No",
      aids: false,
      allergies: false,
      allowSocialMedia: "No",
      asthma: true,
      bloodDisease: false,
      botoxConsent: false,
      btlemSellaConsent: false,
      cancer: false,
      claustrophobia: false,
      createdAt: null,
      dermaShine: false,
      diabetes: false,
      dmk: false,
      eczema: false,
      epilepsy: false,
      fatfreezing: false,
      fillersConsent: false,
      flatMolePiQo4: false,
      fotona: false,
      heartCondition: false,
      id: 1,
      infectiousDisease: false,
      ipl: false,
      lumenisConsent: false,
      medications: "",
      miraDryConsent: false,
      muscularPain: false,
      nanosoftConsent: false,
      nuera: false,
      preferredPressure: "",
      pregnancy: false,
      profhilo: false,
      prpConsent: false,
      recentOperation: false,
      signedSignature: true,
      // signedDate: '2022-05-20',
      skinType: "Combination",
      sunburn: false,
      surgeries: "No",
      thermage: false,
      thermageeye: false,
      ultraformerConsent: false,
      updatedAt: null,
      venusDiamondPolar: false,
      venusVivaConsent: false,
      waterRetention: false,
    },
  },
];

// ==============================|| MOCK SERVICES ||============================== //

services.onGet("/api/customer/list").reply(200, { customerList });

services.onGet("/api/customer").reply((request) => {
  try {
    const { customerId } = request.params;
    const customer = _.filter(
      customerDetails,
      (customer) => customer.id === customerId
    );

    return [200, customer];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
