// assets
import { IconBook2, IconClipboardList, IconDeviceAnalytics, IconDiamond, IconGauge, IconPackage, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconBook2,
    IconClipboardList,
    IconDeviceAnalytics,
    IconDiamond,
    IconGauge,
    IconPackage,
    IconUsers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const main = {
    id: 'main',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false,
        // },
        {
            id: 'bookings',
            title: 'Bookings',
            type: 'item',
            url: '/bookings',
            icon: icons.IconBook2,
            breadcrumbs: false,
        },
        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/customers',
            icon: icons.IconDiamond,
            breadcrumbs: false
        },
        {
            id: 'services',
            title: 'Services',
            type: 'item',
            url: '/services',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        },
        // {
        //     id: 'package-deals',
        //     title: 'Package Deals',
        //     type: 'item',
        //     url: '/package-deals',
        //     icon: icons.IconPackage,
        //     breadcrumbs: false
        // },
        {
            id: 'staff',
            title: 'Staff',
            type: 'item',
            url: '/staff',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
    ]
};

export default main;
