import { useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, Typography, useMediaQuery } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
// import User1 from 'assets/images/users/user-round.svg';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// assets
import { IconLogout } from '@tabler/icons';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const { logout } = useAuth();

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Chip
      sx={{
        height: matchesXs ? '40px' : '48px',
        alignItems: 'center',
        borderRadius: matchesXs ? '20px' : '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
          borderColor: theme.palette.primary.main,
          background: `${theme.palette.primary.main}!important`,
          color: theme.palette.primary.light,
          '& svg': {
            stroke: theme.palette.primary.light
          }
        },
        '& .MuiChip-label': {
          lineHeight: 0
        }
      }}
      icon={
        <Avatar
          sx={{
            ...theme.typography.mediumAvatar,
            margin: '8px 0 8px 8px !important',
            cursor: 'pointer',
            width: { xs: '28px', md: '32px' },
            height: { xs: '28px', md: '32px' },
          }}
          ref={anchorRef}
          aria-haspopup="true"
          color="inherit"
        >
          <Typography variant="h5" sx={{ textTransform: 'capitalize' }} color="inherit">
            {firebase?.auth()?.currentUser?.email?.substring(0, 1)}
          </Typography>
        </Avatar>
      }
      label={<IconLogout stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
      variant="outlined"
      ref={anchorRef}
      aria-haspopup="true"
      onClick={handleLogout}
      color="primary"
    />
  );
};

export default ProfileSection;
