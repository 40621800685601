// types
import { ConfigProps } from "types/config";

export const TIMEZONE = "Pacific/Auckland";

export const FIREBASE_API = {
  apiKey: "AIzaSyDE03NoRW78p_l2YL2fkXVlX7QHuIZgYjs",
  authDomain: "lanree.firebaseapp.com",
  projectId: "lanree",
  storageBucket: "lanree.appspot.com",
  messagingSenderId: "872417129784",
  appId: "1:872417129784:web:7441ba49e6ae179fc8bdcd",
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/lanree/react/default'
export const BASE_PATH = "";

export const DASHBOARD_PATH = "/bookings";

export const API_HOST = process.env.REACT_APP_API_HOST;

const config: ConfigProps = {
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: "light", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export default config;
