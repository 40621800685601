// third-party
import { createSlice } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { API_HOST } from 'config';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['staff'] = {
    error: null,
    staffs: [],
};

const slice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET STAFFS
        getStaffSuccess(state, action) {
            state.staffs = action.payload;
        },

        // GET THERAPISTS
        // getTherapistSuccess(state, action) {
        //     state.staffs = action.payload;
        // },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStaffs() {
  return async () => {
    try {
      const response = await axios.get(`${API_HOST}/staff`);
      dispatch(slice.actions.getStaffSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createStaff(staff: FormikValues) {
  return async () => {
    try {
        await axios.post(`${API_HOST}/staff`, staff);
        // dispatch(openSnackbar(snackbar('New staff added')));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStaff(staffId: number, data: FormikValues) {
  return async () => {
    try {
      await axios.patch(`${API_HOST}/staff/${staffId}`, data);
      // dispatch(openSnackbar(snackbar(`Customer (id: ${customerId}) updated`)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStaff(id: number) {
  return async () => {
    try {
      await axios.delete(`${API_HOST}/staff/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getTherapist() {
//     return async () => {
//         try {
//             const response = await axios.get(`${API_HOST}/staff/?role=Therapist`);
//             dispatch(slice.actions.getTherapistSuccess(response.data));
//         } catch (error) {
//             console.log(error);
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
