// third-party
import { createSlice } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import { utcToZonedTime } from "date-fns-tz";

// project imports
import axios from "axios";
import { dispatch } from "../index";
import { openSnackbar } from "store/slices/snackbar";
import { API_HOST, TIMEZONE } from "config";

// types
import { DefaultRootStateProps } from "types";

// ----------------------------------------------------------------------
const snackbar = (message: string) => ({
  open: true,
  message,
  variant: "alert",
  close: false,
  transition: "SlideDown",
  anchorOrigin: { vertical: "top", horizontal: "center" },
});

const initialState: DefaultRootStateProps["customer"] = {
  error: null,
  search: null,
  customers: [],
  selectedCustomer: null,
  pagination: {
    page: 1,
    total: 0,
  },
  transactions: null,
  bookings: null,
  loading: false,
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CUSTOMERS
    getCustomersSuccess(state, action) {
      state.customers = action.payload.records;
      state.pagination = action.payload.pagination;
    },

    // UPDATE CUSTOMER SEARCH STRING
    updateCustomerSearchStrSuccess(state, action) {
      state.search = action.payload;
    },

    // GET CUSTOMER DETAILS
    getCustomerDetailsSuccess(state, action) {
      state.selectedCustomer = action.payload;
    },

    // GET TRANSACTIONS
    getTransactionsSuccess(state, action) {
      state.transactions = action.payload;
    },

    // GET BOOKINGS
    getBookingsSuccess(state, action) {
      state.bookings = action.payload;
    },

    // SET LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers(search: string | null, page?: number) {
  return async () => {
    try {
      if (!!search) {
        const url = !!search
          ? `${API_HOST}/customer?search=${search}&page=${
              page || 1
            }&pageSize=10`
          : `${API_HOST}/customer?page=${page || 1}&pageSize=10`;
        const response = await axios.get(url);
        dispatch(slice.actions.getCustomersSuccess(response.data));
        return response.data.records;
      } else {
        dispatch(
          slice.actions.getCustomersSuccess({
            records: [],
            pagination: { total: 0 },
          })
        );
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCustomerSearchStr(search: string | null) {
  return async () => {
    try {
      dispatch(slice.actions.updateCustomerSearchStrSuccess(search));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function checkExistingCustomer(
  phone: number | null,
  email: string | null
) {
  return async () => {
    try {
      const url = `${API_HOST}/customer?phone=${phone}&email=${email}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerDetails(id: number | null) {
  return async () => {
    try {
      if (!id) {
        dispatch(slice.actions.getCustomerDetailsSuccess(null));
        dispatch(slice.actions.setLoading(false));
      } else {
        dispatch(slice.actions.setLoading(true));
        const response = await axios.get(`${API_HOST}/customer/${id}`);
        dispatch(slice.actions.getCustomerDetailsSuccess(response.data));
        dispatch(slice.actions.setLoading(false));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function createCustomer(customer: FormikValues) {
  return async () => {
    try {
      const response = await axios.post(`${API_HOST}/customer`, customer);
      dispatch(openSnackbar(snackbar("New customer added")));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCustomer(id: number, data: FormikValues) {
  return async () => {
    try {
      await axios.patch(`${API_HOST}/customer/${id}`, data);
      dispatch(openSnackbar(snackbar(`Customer (id: ${id}) updated`)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCustomer(id: number) {
  return async () => {
    try {
      await axios.delete(`${API_HOST}/customer/${id}`);
      dispatch(openSnackbar(snackbar(`Customer (id: ${id}) deleted`)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTransactions(id: number | null) {
  return async () => {
    try {
      if (!id) {
        dispatch(slice.actions.getTransactionsSuccess(null));
      } else {
        const response = await axios.get(
          `${API_HOST}/customer/${id}/transaction`
        );
        dispatch(slice.actions.getTransactionsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookings(id: number | null) {
  return async () => {
    try {
      if (!id) {
        dispatch(slice.actions.getBookingsSuccess(null));
      } else {
        const response = await axios.get(`${API_HOST}/customer/${id}/booking`);
        const bookings = response.data
          .filter((b: any) => b.status !== "Inactive")
          .map((b: any) => {
            b = {
              ...b,
              start: utcToZonedTime(b.startTime, TIMEZONE),
              end: utcToZonedTime(b.endTime, TIMEZONE),
            };
            delete b.startTime;
            delete b.endTime;
            return b;
          });

        dispatch(slice.actions.getBookingsSuccess(bookings));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function topUp(id: number, data: FormikValues) {
  return async () => {
    try {
      await axios.post(`${API_HOST}/customer/${id}/top-up`, data);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGoogleSheetData() {
  return async () => {
    try {
      await axios.get(`${API_HOST}/customer/update-data`);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
