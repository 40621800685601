// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import staffReducer from './slices/staff';
import serviceReducer from './slices/service';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import errorReducer from './slices/error';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  calendar: calendarReducer,
  customer: customerReducer,
  staff: staffReducer,
  service: serviceReducer,
  product: productReducer,
  user: userReducer,
  menu: menuReducer,
  error: errorReducer,
});

export default reducer;
