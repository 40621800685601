// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Reminder from 'ui-component/Reminder';
import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* RTL layout */}
    <RTLLayout>
      <NavigationScroll>
        <AuthProvider>
          <>
            <Routes />
            <Snackbar />
            <Reminder />
          </>
        </AuthProvider>
      </NavigationScroll>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
